.delivery{
    width:100%;
    margin-top:10;
    padding:1%;
    position:relative;
    background-color:#ffffff;
    border-radius: 5px;
    box-shadow: 4px 4px 4px #f5f5f5;
}
.address{
    width:100% !important;
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
    padding: 8px;
    position: relative;
}
.address .one{
    font-size: 22px;
    font-weight: 700;
    color: #000000;
    margin-bottom: 5px;
}

.address .two{
    font-size: 15px;
    font-weight: 700;
    color: #919191;
    margin-bottom: 5px;
}
.address div{
    display: flex;
    flex-direction: row;
}

.address .edit-address{
    position: absolute;
    top: 5px;
    right: 5px;
    color:var(--app-color);
    width: 20px;
    height: 20px;

}
