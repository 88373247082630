.floating{
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: 100%;
    z-index: 0;
}

.floating-data{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20px;
}

.floating-filter{
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

@media(min-width:200px){
    .floating-filter{
        display: flex;
    }
}

@media(min-width:768px){
    .floating-filter{
        display: none;
    }
}