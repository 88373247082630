.small-screen-header{
    display: none;
    background-color: #ffffff;
    padding: 2px;
}

@media(min-width:200px){
    .small-screen-header{
        display: flex;
    }
}

@media(min-width:768px){
    .small-screen-header{
        display: none;
    }
}