/* General body styling */
.body {
    background-color: white;
    color: black;
    font-family: Arial, sans-serif;
  }
  
  /* Body wrapper for general padding and spacing */
  .body-wrapper {
    padding: 20px;
    max-width: 1200px;
    margin: auto;
    background-color: white;
  }
  
  /* Header section */
  .header {
    background-color: white;
    color: white;
    padding: 15px;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
  }
  
  /* Delivery details container */
  .delivery-details {
    padding: 15px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  /* Order title and status */
  .detials-title h3 {
    font-size: 20px;
    margin-bottom: 10px;
    color: #f3690d;
  }
  
  .detials-title p {
    font-size: 16px;
    color: black;
    margin-bottom: 20px;
  }
  
  /* Customer section */
  .details-customer h4 {
    font-size: 18px;
    color: #f3690d;
    margin-bottom: 10px;
  }
  
  .details-customer label {
    font-size: 16px;
    color: black;
  }
  
  .details-customer a {
    font-weight: bold;
    color: #f3690d;
    text-decoration: none;
  }
  
  .details-customer a:hover {
    text-decoration: underline;
  }
  
  /* Address pin icon */
  .details-customer .FiMapPin {
    color: black;
  }
  
  /* Products section */
  .details-products h4 {
    font-size: 18px;
    color: #f3690d;
    text-decoration: underline;
    margin-bottom: 20px;
  }
  
  /* Product labels */
  .details-products label {
    font-size: 16px;
    color: black;
    margin-bottom: 8px;
  }
  
  .details-products .product-item {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  /* Buttons */
  .custom-button {
    background-color: #f3690d;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .custom-button:hover {
    background-color: #d3580d;
  }
  
  .custom-button:disabled {
    background-color: grey;
    cursor: not-allowed;
  }
  
  /* Toast message */
  .toast {
    background-color: red;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    padding: 10px;
  }
  
  .toast-body {
    font-size: 14px;
  }
  
  /* Fixed button at bottom */
  .fixed-bottom {
    position: fixed;
    bottom: 10px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  
  /* Small screen header */
  .small-screen-header {
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    color: #f3690d;
    margin-bottom: 20px;
  }
  
  @media (max-width: 800px) {
    /* Adjust the padding and spacing for mobile */
    .body-wrapper {
      padding: 10px;
    }
  
    /* Fixed button for smaller screens */
    .fixed-bottom {
      width: 97%;
    }
  }
  