.custom_button{
    height: 40px;
    width: 100%;
    border: none;
    outline: none;
    border-radius: 5px;
    background-color: #040e0a;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap:5px;
}