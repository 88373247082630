.body{
    background-color: #ffffff;
    position: relative;
}
.checkout-body{
    width: 100%;
    background-color: #ffffff;
    position: relative;
    height: 100vh;
    margin-top: 80px;
}
.checkout-body .checkout-wrapper{
    width: 80%;
}
.checkout-body .checkout-wrapper .holder{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
    margin-bottom: 20px;
}
.checkout-body .checkout-wrapper .holder .order-now{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.checkout-body .checkout-wrapper .holder .order-now input{
    height: 40px;
    padding-left: 10px;
}
.checkout-body .checkout-wrapper .holder .order-now .order-now-buttons{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 1%;
}

.address-in-checkout{
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 2px;
    background-color: #f5f5f5;
    border-radius: 5px;
    margin: 1%;
    margin-bottom: 10px;
    width: 98%;
}

table{
    width: 100%;
    border-spacing: 10px;
}
table th{
    text-align: left;
}

table .gap{
    width: 20%;
}


@media(min-width:200px){
    .checkout-body{
        margin-top: 50px;
        width: 100%;
       
    }
    .checkout-body .checkout-wrapper{
        width: 98%;
        margin-left: 0%;
        margin: 0%;
        padding: 2%;
    }
    .checkout-body .checkout-wrapper .holder{
        flex-direction: column;
    }
}

@media(min-width:768px){
    .checkout-body{
        margin-top: 80px;
        width: 100%;
        padding: 0%;
    }
    .checkout-body .checkout-wrapper{
        width: 80%;
        margin-left: 10%;
         padding: 0%;
    }
    .checkout-body .checkout-wrapper .holder{
        flex-direction: row;
    }
}