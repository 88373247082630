.body{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: 0px;
}
.body .product_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 80% ;
    position: relative;
}

.body .product_container .product_data{
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 30px;
}

.body .product_container .description{
    width:100%;
    height: auto;
    margin-top: 10px;
}

.body .product_container .description div{
    width: 100%;
}

.body .product_container .description div .title{
    border-bottom: 1px solid #585757;
    /*border-top: 1px solid #000000;*/
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 400;
}

.body .product_container .product_data .product_image{
    width: 100%;
    height: 280px;
    background-color: #ffffff;
    border-radius: 5px;
}

.body .product_container .product_data .product_image img{
    width: 100%;
    height: 100%;
    border-radius: 5px;
    object-fit: cover;
}

.body .product_container .product_data .seller{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 0px;
}


.body .product_container .product_data .seller .name{
    font-size: 19px;
    font-weight: 600;
    margin-left: 2%;
    width: 96%;
}

.body .product_container .product_data .seller .subName{
    font-size: 14px;
    font-weight: 400;
    color: #919191;
    margin-left: 2%;
    width: 96%;
}

.body .product_container .product_data .seller .pricing{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-left: 2%;
    width: 96%;
    margin-right: 3px;
}

.body .product_container .product_data .seller .pricing div{
    display: flex;
    flex-direction: row;
    gap: 5px;
    height: fit-content;
    align-items: center;
}
.body .product_container .product_data .seller .pricing .price{
    font-size: 18px;
    font-weight: 500;
    
}
.body .product_container .product_data .seller .pricing div .from{
    font-size: 10px;
    font-weight: 500;
    color: #919191;
    
}

.body .product_container .product_data .seller .pricing div label{
    font-size: 11px;
    font-weight: 500;
    color: #000000;
}

.body .product_container .product_data .seller .shipping{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    margin-left: 2%;
    width: 96%;
}

.body .product_container .product_data .seller .rating{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    margin-left: 2%;
    width: 96%;
}


.body .product_container .product_data .seller .seller_contacts{
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap:nowrap;
}

.body .product_container .description{
    display: flex;
    flex-direction: row;
}

.body .related_products{
    width: 80%;
}

.body .related_products .heading{

color: #919191;
color: #000000;
}



.body .product_container .description .big-screen-bag{
    width: 100%;
    display: flex;
    margin-top: 20px;
    margin-left: 0px;
}

.body .small-screen-bag{
    width: 100%;
    display: none;
    margin-left: 0px;
    left: 0px;
    
}


@media(min-width:200px){
    .body .product_container{
        width: 100%;
        padding: 0%;
        margin-top: 0px;
    }
    .body .product_container .product_data{
        flex-direction: column;
    }
    .body .related_products{
        width: 98%;
    }

    .body .product_container .product_data .product_image{
        height: 350px;
        border-radius: 0px;
    }

    .body .product_container .product_data .product_image img{
        border-radius: 0px;
    }

    .body .product_container .description .big-screen-bag{
        width: 100%;
        display: none;
    }

    .body .small-screen-bag{
        width: 100%;
        display: flex;
        position: fixed;
        bottom: 0px;
    }
}

@media(min-width:768px){
    .body .product_container{
        width: 80%;
        margin-top: 20px;
        padding: 0%;
    }
    .body .product_container .product_data{
        flex-direction: row;
    }
    .body .related_products{
        width: 80%;
    }
    .body .product_container .product_data .product_image{
        height: 400px;
        border-radius: 5px;
    }
    .body .product_container .product_data .product_image img{
        border-radius: 5px;
    }
    .body .product_container .description .big-screen-bag{
        width: 100%;
        display: flex;
    }

    .body .small-screen-bag{
        width: 100%;
        display: none;
    }
}
