.chats{
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 76px;
    position: relative;
    gap: 5px;
}

.chats .side-menu-chats{
    width: 25%;
    height: 100vh;
    box-shadow: 2px 2px 2px grey;
}

.chats .side-menu-chats .side-menu-chats-header{
    width: 100%;
    height: 50px;
    background-color: orange;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chats .chat-area{
    margin-top: 10px;
    width:75%;
    position: relative;
}

.chats .chat-area .chat-area-header{
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.chats .chat-area .chat-area-header img{
    width:60px;
    height:60px;
    border-radius:30px;
    object-fit:"cover"
}

.chats .chat-area .chat-area-messages{
    width: 100%;
}

.chats .chat-area .chat-area-compose-message{
    width: 100%;
    position: absolute;
    bottom: 85px;
    display: flex;
    flex-direction: row;
    gap: 2px;
    align-items: center;
}

.chats .chat-area .chat-area-compose-message textarea{
    width: 90%;
    height: fit-content;
    padding: 5px;
    outline: none;
    border-radius: 5px;
    border: 1px solid #000000;
    display: flex;
    align-items: center;
}

.chats .chat-area .chat-area-compose-message .send-icon{
    width: 5%;
    height: fit-content;
}