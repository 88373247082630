.bottom-tabs{
    width: 100%;
    height: 80px;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: fixed;
    bottom: 0px;
    background-color: #ffffff;
    z-index: 110;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    left: 0px;
}

.bottom-tabs .bottom-tabs-icon{
    height: 90%;
    display: flex;
    flex-direction: column;
    gap: 1px;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

.bottom-tabs .bottom-tabs-icon .icon{
    width:26px;
    height: 26px;
}

.bottom-tabs .bottom-tabs-icon .title{
    font-size: 16px;
}


@media(min-width:300px){
    .bottom-tabs{
        display: flex;
    }
}

@media(min-width:768px){
    .bottom-tabs{
        display: none;
    }
}