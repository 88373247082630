.products-loading{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 10px;
    background-color: #f1f1f1;
}

.products-loading .product{
    height: 300px;
    background-color: #f5f5f5;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.products-loading .product .product-img{
    height: 60%;
    width: 100%;
    background-color: transparent;
    border-radius: 5px;
    background:linear-gradient(-60deg,#868686 10%,#aaa9a955,#cac8c855 70%) right/300% 100%;
    background-repeat: no-repeat;
    animation: shimmer 2.5s infinite;
}

.products-loading .product .label-one{
    height: 40px;
    width: 98%;
    background-color: transparent;
    border-radius: 5px;
    background:linear-gradient(-60deg,#868686 10%,#aaa9a955,#cac8c855 70%) right/300% 100%;
    background-repeat: no-repeat;
    animation: shimmer 2.5s infinite;
}

.products-loading .product .label-two{
    height: 20px;
    width: 50%;
    background-color: transparent;
    border-radius: 5px;
    background:linear-gradient(-60deg,#868686 10%,#aaa9a955,#cac8c855 70%) right/300% 100%;
    background-repeat: no-repeat;
    animation: shimmer 2.5s infinite;
}
/*** Category loading ***/
.categories-loading{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1,1fr);
    gap: 10px;
    background-color: #f1f1f1;
}
.categories-loading .category{
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 5px;
    height: 200px;
    background-color: #f5f5f5;
}

.categories-loading .category .category-img{
    height: 70%;
    width: 100%;
    background:linear-gradient(-60deg,#868686 10%,#aaa9a955,#cac8c855 70%) right/300% 100%;
    background-repeat: no-repeat;
    animation: shimmer 2.5s infinite;
}



.categories-loading .category .category-label{
    height: 15%;
    width: 90%;
    border-radius: 5px;
    background:linear-gradient(-60deg,#868686 10%,#aaa9a955,#cac8c855 70%) right/300% 100%;
    background-repeat: no-repeat;
    animation: shimmer 2.5s infinite;

}

.home-categories-loading{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.home-categories-loading .category{
    display: flex;
    flex-direction: row;
    gap: 5%;
    border-radius: 5px;
    height: 40px;
    background-color: #f5f5f5;
    width: 98%;
    margin: 1%;
}

.home-categories-loading .category .category-img{
    height: 100%;
    width: 15%;
    background:linear-gradient(-60deg,#868686 10%,#aaa9a955,#cac8c855 70%) right/300% 100%;
    background-repeat: no-repeat;
    animation: shimmer 2.5s infinite;
}



.home-categories-loading .category .category-label{
    height: 100%;
    width: 80%;
    border-radius: 5px;
    background:linear-gradient(-60deg,#868686 10%,#aaa9a955,#cac8c855 70%) right/300% 100%;
    background-repeat: no-repeat;
    animation: shimmer 2.5s infinite;

}


/*** Product loading ***/
.product-loading{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.product-loading .product-img{
    width: 100%;
    height: 400px;
    border-radius: 5px;
    background:linear-gradient(-60deg,#868686 10%,#aaa9a955,#cac8c855 70%) right/300% 100%;
    background-repeat: no-repeat;
    animation: shimmer 2.5s infinite;
}

.product-loading .desc{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.product-loading .desc .desc-one{
    width: 80%;
    height: 30px;
    border-radius: 5px;
    background:linear-gradient(-60deg,#868686 10%,#aaa9a955,#cac8c855 70%) right/300% 100%;
    background-repeat: no-repeat;
    animation: shimmer 2.5s infinite;
}

.product-loading .desc .desc-two{
    width: 100%;
    height: 100px;
    border-radius: 5px;
    background:linear-gradient(-60deg,#868686 10%,#aaa9a955,#cac8c855 70%) right/300% 100%;
    background-repeat: no-repeat;
    animation: shimmer 2.5s infinite;
}

.product-loading .desc .desc-three{
    width: 100%;
    height: 30px;
    border-radius: 5px;
    background:linear-gradient(-60deg,#868686 10%,#aaa9a955,#cac8c855 70%) right/300% 100%;
    background-repeat: no-repeat;
    animation: shimmer 2.5s infinite;
}

.product-loading .desc .desc-three{
    width: 70%;
    height: 30px;
    border-radius: 5px;
    background:linear-gradient(-60deg,#868686 10%,#aaa9a955,#cac8c855 70%) right/300% 100%;
    background-repeat: no-repeat;
    animation: shimmer 2.5s infinite;
}



@keyframes shimmer {
    100% {
        background-position: left;
    }
}
@media(min-width:200px){
    .products-loading{
        grid-template-columns: repeat(2,1fr);
        gap: 15;
    }
    .categories-loading{
        grid-template-columns: repeat(2,1fr);
    }

    .product-loading{
        flex-direction: column;
    }
    .product-loading .product-img{
        border-radius: 0px;
    }
}

@media(min-width:650px){
    .products-loading{
        grid-template-columns: repeat(3,1fr);
    }
    
}

@media(min-width:768px){
    .categories-loading{
        grid-template-columns: repeat(3,1fr);
    }
    .product-loading{
        flex-direction: row;
    }
    .product-loading .product-img{
        border-radius: 5px;
    }
}

@media(min-width:850px){
    .products-loading{
        grid-template-columns: repeat(4,1fr);
    }
}