.header{
    top:0px;
    z-index: 20;
    height: 75px;
    width:100%;
    background-color: #ffffff;
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    box-shadow: 3px 3px 3px rgba(0,0,0,0.1);
}
.header .logo-and-hamburger{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.header .logo-and-hamburger .logo{
    color: #225ea3;
    font-size: 30px;
    text-decoration: none;
}
.header .logo-and-hamburger .hamburger{
    width: 20px;
    height: 20px;
    display: none;
}
.header .search{
    width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
}
.header .search .search-input{
    width: 100%;
    height: 45px;
    outline: none;
    border-radius: 5px;
    font-size: 17px;
    padding-left: 30px;
    border: none;
}

.header .search .search-results{
    position: absolute;
    max-height: 400px;
    min-height: 100px;
    background-color: #ffffff;
    top: 82%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.header .search .search-results::-webkit-scrollbar {
    display: none;
}

.header .search .search-input:focus{
    border: 1px solid var(--app-color);
}
.header .search .search-icon{
    position: absolute;
    margin-left: 5px;
}
.header .sell{
    padding: 0px 5px 0px 5px;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--app-color);
    text-decoration: none;
    color: #ffffff;
    border-radius: 5px;
    cursor: pointer;
}

.header .signin-signup{
    text-decoration: none;
    font-size: 18px;
    color: #ffffff;
    display: flex;
    position: relative;
}

@media(min-width:200px){
    .header{
        flex-direction: column;
        height: 100px;
        display: none;
    }
    .header .logo-and-hamburger{
        width: 96%;
    }
    .header .logo-and-hamburger .hamburger{
        display: flex;
    }
    .header .search{
        width: 96%;
    }
    .header .sell{
        display: none;
    }
    .header .signin-signup{
        display: none;
    }
}

@media(min-width:768px){
    .header{
        flex-direction: row;
        height: 75px;
        display: flex;
    }
    .header .logo-and-hamburger{
        width: auto;
    }
    .header .logo-and-hamburger .hamburger{
        display: none;
    }
    .header .search{
        width: 40%;
    }
    .header .sell{
        display: none;
    }
    .header .signin-signup{
        display: flex;
    }
}