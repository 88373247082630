.banner-product{
    width: auto !important;
    height: 260px!important;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 5px;
    text-decoration: none;
    color: #000000;
               
}

.banner-product .banner-image{
    height: 60%;
    width: 100%;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    object-fit: cover;
    border-radius: 5px;
    position: relative;
}

.banner-product .banner-image img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.banner-product .banner-image .default{
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
    position: absolute;
    z-index: 8;
    display:flex;
    top: 0%;
}

.banner-product .description{
    display: flex;
    flex-direction: column;
    position: relative;
    height: 30%;
}

.banner-product .description .productName{
    font-size: 16px;
    margin-left: 8px;
    margin-top: 0px;
}
.banner-product .description .productShop{
    font-size: 11px;
    margin-left: 8px;
    color: #b9b4b4;
}
.banner-product .description .productPrice{
    font-size: 14px;
    margin-left: 8px;
    color: var(--app-color);
}

.banner-product .description .phoneNumber{
    position: absolute;
    right: 10px;
    bottom: 10px;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: #29eb33;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

