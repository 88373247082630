.banner-holder{
    width:100% !important;
    height: auto;
    gap: 10px;
    overflow-x: auto;
    display: flex;
    scroll-behavior: smooth;
    position: relative;
    margin-bottom: 20px;
    background-color: #f5f5f5;
}
.banner-holder::-webkit-scrollbar{
    display: none;
}

.banner-holder .move_button{
    position: absolute !important;
    height: 100% !important;
    width: 80px !important;
    /*background: linear-gradient(90deg,#f5f5f5,0%,#ffffff,100%,#f5f5f5);*/
    display: none;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    z-index: 1;
    transition: .5s ease-in-out;
}



.banner-holder .move_button .icon{
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: var(--app-color);
    display: flex;
    align-items: center;
    justify-content: center;
}

.move_button_visible{
    opacity: 1;
}

.move_button_hidden{
    opacity: 0;
}

.banner-holder .prev{
    left: 0px !important;
}

.banner-holder .next{
    right: 0px !important;
}

.banner-holder .banner-container{
    width:100% !important;
    height: auto;
    gap: 10px;
    overflow-x: auto;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    scroll-behavior: smooth;
    position: relative;
    background-color: #ffffff;
    padding: 5px;
}

.banner-holder .banner-container::-webkit-scrollbar{
    display: none;
}


.data-holder{
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}


@media(min-width:200px){
    .banner-holder .banner-container{
        grid-template-columns: repeat(2,1fr);
        gap: 15px;
    }
}

@media(min-width:650px){
    .banner-holder .banner-container{
        grid-template-columns: repeat(3,1fr);
    }
}

@media(min-width:850px){
    .banner-holder .banner-container{
        grid-template-columns: repeat(4,1fr);
    }
}