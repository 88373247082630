.rate-product{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.rate-product div{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
}

.rate-product textarea{
    width: 100%;
    padding: 5px;
    border: none;
    background-color: #f5f5f5;
    border-radius: 5px;
    
}

.product-rating{
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: row;
    gap: 10px;
    background-color: #f5f5f5;
    border-radius: 5px;
    padding: 5px;
}

.product-rating img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.product-rating .product-rating-holder{
    display: flex;
    flex-direction: column;
}

.product-rating .product-rating-holder label{
    font-size: 16px;
    font-weight: bold;
}

.product-rating .product-rating-holder p{
    font-size: 16px;
}