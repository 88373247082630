.order{
    width: 98%;
    margin: 1%;
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
    border-radius: 5px;
    padding: 5px;
}

.order .title{
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.order .payment{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.order .payment .item{
    width: 100%;
    height: 110px;
    display: flex;
    flex-direction: row;
    text-decoration: none;
    color: #000000;
    
}

.order .payment .item img{
    width: 40%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.order .payment .item div{
    width: 60%;
    padding: 5px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.order .payment .item div label:nth-child(1){
    font-size: 16px;

}
.order .payment .item div label:nth-child(2){
    font-size: 16px;

}
.order .payment .item div label:nth-child(3){
    font-size: 16px;
    font-weight: bold;
}

@media(min-width:200px){
    .order .payment .item img{
        width: 40%;
    }
    .order .payment .item div{
        width: 60%;
    }
}

@media(min-width:768px){
    .order .payment .item img{
        width: 20%;
    }
    .order .payment .item div{
        width: 80%;
    }
}