.search-result{
    height: auto;
    width: 98%;
    margin: 1%;
    display: flex;
    flex-direction: row;
    background-color: #f5f5f5;
    border-radius: 5px;
    text-decoration: none;
    color: #000000;
    gap: 10px;
    padding: 2px;
}

.search-result img{
    height: 50px;
    object-fit: cover;
    width: 50px;
    border-radius: 5px;
}

.search-result .name-and-price{
    height: auto;
    object-fit: contain;
    width: auto;
    display: flex;
    flex-direction: column;
}

.search-result .name-and-price label:nth-child(1){
    font-size: 16px;
    font-weight: 600;
}

.search-result .name-and-price label:nth-child(2){
    font-size: 13px;
    font-weight: 500;
    color: #919191;
}