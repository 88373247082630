.header-hero-home{
    width: 100%;
    height: 60px;
    margin-top: 40px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.header-hero-home .heading{
    font-weight: 200px;
    font-size: 14px;
}

.header-hero-home .view-more{
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    text-decoration: none;
    transition: .5s ease-in-out;
    padding: 5px;
    color: #000000;
    font-size: 14px;
}

