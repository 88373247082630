.body{
    width:100%;
    height:100vh;
    background-color: #ffffff;
}
.body .login_body{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    background-color: #ffffff;
}

.body .login_body img{
    height: 100%;
    object-fit: cover;
    width: 50%;
}

.body .login_body .container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width:100%;
    height: 100%;
    background-color: #ffffff;
}

.body .login_body .container .login_credentials{
    width:90%;
    height:100%;
    background-color: #ffffff;
}

.body .login_body .container .login_credentials .website_name{
    font-size: 30px;
    font-weight: bolder;
    color:#225ea3;
    display: flex;
    flex-direction: row;
    text-decoration: none;
}

.body .login_body .container .login_credentials .hello{
    font-size: 40px;
    font-weight: 700;
    color:#225ea3;
}

.body .login_body .container .login_credentials .login_into_account{
    font-size: 23px;
    font-weight: 700;
    color:#225ea3;
}

.body .login_body .container .login_credentials .credentials_container{
    width:100%;
    display: flex;
    flex-direction: column;
}

.body .login_body .container .login_credentials .credentials_container label{
    margin-top: 40px;
    font-weight: 600;
    color:#000000;
}

.body .login_body .container .login_credentials .credentials_container input{
    margin-top: 3px;
    padding-left: 5px;
    height: 40px;
    border-radius: 5px;
    width: 100%;
    border: 2px solid #a8a6a6;
    outline: none;
}

.body .login_body .container .login_credentials .credentials_container .login_register{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
}

.body .login_body .container .login_credentials .credentials_container .login_register .login-btn{
    width: 100%;
}

.body .login_body .container .login_credentials .credentials_container .login_register div{
    width:40%;
}
.body .login_body .container .login_credentials .credentials_container .login_register p{
    font-size: 16px;
    font-weight: 700;
}
.body .login_body .container .login_credentials .credentials_container .login_register p .link{
    color:var(--app-color);
    text-decoration: none;
}

@media(min-width:200px){
    .body .login_body img{
        display: none;
    }
    .body .login_body .container .login_credentials .credentials_container .login_register{
        flex-direction: column;
    }
    .body .login_body .container .login_credentials .credentials_container .login_register .login-btn{
        width: 100%;
    }
}

@media(min-width:768px){
    .body .login_body img{
        display: flex;
    }
    .body .login_body .container .login_credentials .credentials_container .login_register{
        flex-direction: row;
    }
    .body .login_body .container .login_credentials .credentials_container .login_register .login-btn{
        width: 50%;
    }
}

