.side-bar{
    height:fit-content;
    background-color: #ffffff;
    border: none;
    border-radius: 5px;
    /*box-shadow: 2px 2px 2px 2px #eceff3;*/
    display: flex;
    flex-direction: column;
    text-decoration: none;
    transition: .5s all ease-in-out;
}
.side-bar .category-name{
    text-decoration: none;
    margin-top: 10px;
    margin-left: 10px;
    font-size: 18px;
    color: #000000;
}
.side-bar .item{
    text-decoration: none;
    margin-top: 10px;
    margin-left: 20px;
    font-size: 18px;
    color: #000000;
}

.side-bar .item:last-child{
    margin-bottom: 10px;
}


.sticky{
    position: fixed;
    top: 80px !important;
    width:30%;
}

.not-sticky{
    position: relative;
}
