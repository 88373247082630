.advert{
    margin-top: 100px;
    height: 300px;
    width:100%;
    background-color: #030c33;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    color: #ffffff;
    padding: 2px;
    font-size: 30px;
    border-radius: 5px;
}

.advert .company-name{
    color: #ffffff;
    border-radius: 5px;
    font-size: 23px;
    text-decoration: none;
}

.advert .shop-now{
    width: 20%;
    height: 45%;
    background-color: var(--app-color);
    color: #ffffff;
    border-radius: 5px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.star{
    width: 20px;
    height: 20px;
}
@media(min-width:200px){
    .advert{
        font-size: 10px !important;
        height: 30px;
        margin-top: 110px;
        display: none;
    }
    .advert .company-name{
        font-size: 12px;
    }
    .advert .shop-now{
        font-size: 10px;
    }
    .star{
        width: 10px;
        height: 10px;
    }
}

@media(min-width:600px){
    .advert{
        font-size: 23px !important;
        height: 45px;
        display: none;
        
    }
    .advert .shop-now{
        font-size: 16px;
    }

    .advert .company-name{
        font-size: 16px;
    }
    .star{
        width: 15px;
        height: 15px;
    }
}

@media(min-width:768px){
    .advert{
        font-size: 30px !important;
        height: 80px;
        margin-top: 100px;
        display: flex;
    }

    .advert .company-name{
        font-size: 23px;
    }
    .advert .shop-now{
        font-size: 14px;
    }
    .star{
        width: 20px;
        height: 20px;
    }
}