.categories{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 10px;
}

@media(min-width:300px){
    .categories{
        grid-template-columns: repeat(2,1fr);
    }
}

@media(min-width:768px){
    .categories{
        grid-template-columns: repeat(3,1fr);
    }
}