.small-screen-sidebar{
    height: 100vh;
    width: 350px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 3;
    transition: .5s ease-in-out;
}