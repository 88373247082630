
.products-wrapper{
    margin-top: 100px;
    position: relative;
    flex: 1;
    background-color: #ffffff;
    width: 80%;
    display: flex;
    flex-direction: column;
    gap:10px;
}

.products-wrapper .buttons{
    position: absolute;
    right:5px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    bottom:10px;

}
@media(min-width:200px){
    .products-wrapper{
        margin-top: 10px;
        width: 100%;
    }
}

@media(min-width:768px){
    .products-wrapper{
        margin-top: 50px;
        width: 80%;
    }
}